const articleGallery = {
    _galleries: [],

    init() {
        let self = this;
        if(document.querySelectorAll('.typography').length <= 0) {
            return;
        }

        const scriptUrls = [
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/lightgallery.min.js',
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/plugins/zoom/lg-zoom.min.js',
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/plugins/thumbnail/lg-thumbnail.min.js'
          ];

        const cssUrl = 'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/css/lightgallery-bundle.min.css';

        window.initPackages([scriptUrls, cssUrl]).then(() => {
            self._galleryConstruct();
        }).catch((error) => {
            console.error(error);
        });

    },

    _galleryConstruct() {
        let self = this;
        const articleGalleries = document.querySelectorAll('.typography')

        if(articleGalleries.length > 0) {
            articleGalleries.forEach((articleGallery, index) => {
                let items = articleGallery.querySelectorAll('img');
                items.forEach(item => {
                    if(item.closest('a')) {
                        item.classList.add('no-resize');
                    }

                    if(item.closest('.editor-highlight__icon')) {
                        item.classList.add('no-resize');
                    }

                    if(item.closest('.editor-hero__img')) {
                        item.classList.add('no-resize');
                    }
                });

                self._galleries[index] = lightGallery(articleGallery, {
                    selector: articleGallery.querySelectorAll('img:not(.no-resize)'),
                    plugins: [lgZoom, lgThumbnail],
                    licenseKey: '32088F1F-749D4338-9276BA7C-4A5EF243',
                    speed: 500,
                    exThumbImage: 'data-external-thumb-image',
                });
            });
        }
    },

    destroy() {
        let self = this;

        if(self._galleries.length > 0) {
            self._galleries.forEach((gallery, index) => {
                self._galleries[index].destroy();
            });

            self._galleries = [];
        }
        this.init();
    },
};

articleGallery.init();