const photoGallery = {
    _galleries: [],

    init() {
        let self = this;
        if(document.querySelectorAll('.js-gallery').length <= 0) {
            return;
        }

        const scriptUrls = [
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/lightgallery.min.js',
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/plugins/zoom/lg-zoom.min.js',
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/plugins/thumbnail/lg-thumbnail.min.js'
          ];

        const cssUrl = 'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/css/lightgallery-bundle.min.css';

        window.initPackages([scriptUrls, cssUrl]).then(() => {
            self._galleryConstruct();
        }).catch((error) => {
            console.error(error);
        });

    },

    _galleryConstruct() {
        let self = this;
        const galleries = document.querySelectorAll('.js-gallery')
        console.log(galleries);

        if(galleries.length > 0) {
            galleries.forEach((gallery, index) => {
                self._galleries[index] = lightGallery(gallery, {
                   selector: gallery.querySelectorAll('a'),
                    download: true,
                    preload: 1,
                    licenseKey: '32088F1F-749D4338-9276BA7C-4A5EF243',
                    plugins: [lgZoom, lgThumbnail],
                    exThumbImage: 'data-external-thumb-image',
                });
            });
        }
    },

    destroy() {
        let self = this;
        if(self._galleries.length > 0) {
            self._galleries.forEach((gallery, index) => {
                self._galleries[index].destroy();
            });

            self._galleries = [];
        }
        this.init();
    },
};

photoGallery.init();