let prevHeight = window.innerHeight;

const updateAppHeight = () => {
	document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
}
window.addEventListener('resize', () => {
    if (window.innerHeight !== prevHeight ) {
        prevHeight = window.innerHeight;
        updateAppHeight();
    }
});

updateAppHeight();
