if (!window.packageStore) {
    window.packageStore = {};
}

window.initPackage = function(url) {
    return new Promise(function(resolve, reject) {
      // kontrola, zda balíček již byl načten
        if (window.packageStore[url]) {
            resolve(window.packageStore[url]);
        } else {
            if(url.includes('.css')) {
                let link = document.createElement( 'link' );
                link.rel  = 'stylesheet';
                link.href = url;
                document.head.appendChild(link);

                link.onload = function() {
                    window.packageStore[url] = true;
                    resolve();
                };

                link.onerror = function() {
                    reject(new Error('Nepodařilo se načíst balíček.'));
                };
            } else {
                let script = document.createElement('script');
                script.src = url;
                document.head.appendChild(script);

                script.onload = function() {
                    window.packageStore[url] = true;
                    resolve(true);
                };

                script.onerror = function() {
                    reject(new Error('Nepodařilo se načíst balíček.'));
                };
            }
        }
    });
}

window.initPackages = function (scriptUrls) {
    const promises = [];
    scriptUrls.forEach((url) => {
        if(Array.isArray(url)) {
            url.forEach((urlArr) => {
                if (!window.packageStore[urlArr]) {
                    promises.push(window.initPackage(urlArr));
                }
            })
        }
        else {
            if (!window.packageStore[url]) {
                promises.push(window.initPackage(url));
            }
        }
    });
    return Promise.all(promises);
}