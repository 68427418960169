const animations = {
    _isRegistred: false,

    init() {

		const scriptUrls = [
            'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.4/gsap.min.js',
            'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.4/ScrollTrigger.min.js',
            'https://cdnjs.cloudflare.com/ajax/libs/gsap/latest/plugins/CSSRulePlugin.min.js'
          ];

        window.initPackages(scriptUrls).then(() => {
			let self = this
            self._animateMe();
        }).catch((error) => {
            console.error(error);
        }); 
    },

	_animateMe() {
		let self = this;
        gsap.registerPlugin(ScrollTrigger);
        
        /*if(!self._isRegistred) {
            gsap.registerPlugin(ScrollTrigger);
            self._isRegistred = true;
        }*/

		if(document.querySelectorAll('.js-fade-in-bottom').length > 0) {
            self._fadeInBottom();
        }

        if(document.querySelectorAll('.js-fade-in-right').length > 0) {
            self._fadeInRight();
        }

        if(document.querySelectorAll('.js-img-reveal').length > 0) {
            self._imgReveal();
        }

        if(document.querySelectorAll('.js-fade-in-bottom-single').length > 0) {
            self._singleItem();
        }

        if(document.querySelector('.js-header-anim')) {
            self._sliderAnimSequency();
        }

        window.onload = function() {
            ScrollTrigger.refresh();
        }
	},

    _sliderAnimSequency() {
        let self = this;

        let tl = gsap.timeline({defaults: { ease: 'power4.easeOut', duration: 0.8}});

        tl.to('.js-anim-header-heading', {'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)', y: 0, opacity: 1, duration: 1})
		.to('.js-anim-header-content', {'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)', y: 0, opacity: 1, duration: 1}, "-=0.7");
    },

    _fadeInBottom() {
        let self = this;
        let items = document.querySelectorAll('.js-fade-in-bottom');

		let triggerPosition;

		if(window.innerWidth > 576) {
			triggerPosition = "top 75%";
		}
		else {
			triggerPosition = "top 90%";
		}

        if(window.innerWidth > 768) {
            items.forEach(item => {
                gsap.to(item.querySelectorAll('.js-anim-item'), {
                    scrollTrigger: {
                        trigger: item,
                        start: triggerPosition,
                    },
            
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.5,
                    ease: 'power4.easeOut',
                    stagger: 0.1,    
                });
            });
        }   
        else {
            items.forEach(item => {
                item.querySelectorAll('.js-anim-item').forEach(elem => {
                    gsap.to(elem, {
                        scrollTrigger: {
                            trigger: elem,
                            start: triggerPosition,
                        },
                
                        y: 0,
                        autoAlpha: 1,
                        duration: 0.5,
                        ease: 'power4.easeOut',
                        stagger: 0.1,
                        
                    });
                });
                
            });
        }
        
        
    },

    _fadeInRight() {
        let self = this;
        let items = document.querySelectorAll('.js-fade-in-right');

		let triggerPosition;

		if(window.innerWidth > 576) {
			triggerPosition = "top 75%";
		}
		else {
			triggerPosition = "top 90%";
		}
        
        items.forEach(item => {
            gsap.to(item.querySelectorAll('.js-anim-item'), {
                scrollTrigger: {
                    trigger: item,
                    start: triggerPosition,
                },
        
                x: 0,
                autoAlpha: 1,
                duration: 0.5,
                ease: 'power4.easeOut',
                stagger: 0.1,
            });
        });
    },

    _imgReveal() {
        let self = this;
		let triggerPosition;

		if(window.innerWidth > 576) {
			triggerPosition = "top 75%";
		}
		else {
			triggerPosition = "top 90%";
		}

        let items = document.querySelectorAll(".js-img-reveal");

        items.forEach((item) => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item,
                    start: triggerPosition,
                },
				'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                opacity: 1,
                duration: 1,
                ease: 'power4.easeOut'
            });

        });
    },


    _singleItem() {
        let items = document.querySelectorAll('.js-fade-in-bottom-single');
		let triggerPosition;

		if(window.innerWidth > 576) {
			triggerPosition = "top 75%";
		}
		else {
			triggerPosition = "top 90%";
		}

        items.forEach(item => {
            item.querySelectorAll('.js-anim-item').forEach(elem => {
                gsap.to(elem, {
                    scrollTrigger: {
                        trigger: elem,
                        start: triggerPosition,
                    },
            
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.5,
                    ease: 'power4.easeOut',
                    stagger: 0.1,
                });
            });
            
        });
    }
};

animations.init();