/**
 * Object containing methods related to the card slider.
 */
const slider = {
    _sliders: [],

    /**
     * Initializes the card slider.
     */
    init: function() {
        let self = this;
        let sliders = document.querySelectorAll('.js-slider');
        
        if (sliders.length === 0) return;

        const scriptUrl = 'https://cdn.jsdelivr.net/npm/@splidejs/splide@4.1.4/dist/js/splide.min.js';
        const cssUrl = 'https://cdn.jsdelivr.net/npm/@splidejs/splide@4.1.4/dist/css/splide.min.css';

        window.initPackages([scriptUrl, cssUrl]).then(() => {
            self._sliderCreate(document.querySelectorAll('.js-slider'));
        }).catch((error) => {
            console.error(error);
        });
    },

    /**
     * Creates a new Splide slider instance for each `.js-card-slider` element.
     */

    _sliderCreate(sliders) {
        let self = this;
        sliders.forEach((slider, index) => {
           
            self._sliders[index] = new Splide(slider).mount();
            let bar = self._sliders[index].root.parentElement.querySelector('.splide__progress-bar-inner');
            
  
            
            let end  = self._sliders[index].Components.Controller.getEnd() + 1;
            let rate = Math.min( ( self._sliders[index].index + 1 ) / end, 1 );
            bar.style.width = String( 100 * rate ) + '%';
        

            self._sliders[index].on( 'mounted move', function () {
                let end  = self._sliders[index].Components.Controller.getEnd() + 1;
                let rate = Math.min( ( self._sliders[index].index + 1 ) / end, 1 );
                bar.style.width = String( 100 * rate ) + '%';
            });
            
          
                    
                self._initClickEvent(slider, index);
                    
        });
    },

    /**
     * Initializes click events for the `.js-slider__nav-next` and `.js-slider__nav-prev` buttons.
     */
    _initClickEvent(slider, index) {
        let self = this;

        let nextButton = slider.parentElement.querySelector('.js-slider__nav-next');
        let prevButton = slider.parentElement.querySelector('.js-slider__nav-prev');

        if (nextButton) {
            nextButton.addEventListener('click', () => {
                self._nextSlide(index);
            }, false);
        }

        if (prevButton) {
            prevButton.addEventListener('click', () => {
                self._prevSlide(index);
            }, false);
        }
    },

    /**
     * Goes to the next slide in the specified slider.
     */
    _nextSlide(index) {
        this._sliders[index].go('>');
    },

    /**
     * Goes to the previous slide in the specified slider.
     */
    _prevSlide(index) {
        this._sliders[index].go('<');
    },

    /**
     * Destroys all Splide slider instances and re-initializes the card slider.
     */
    reInit() {
        let self = this;
        let nodes = document.querySelectorAll('.js-slider');

        if (self._sliders.length <= 0) {
           return;
        }

        self._sliders.forEach(slider => {
            slider.destroy();
        });

        self.init();
    },

        /**
         * Destroys all Splide slider instances.
         */
    _destroy() {
        let self = this;
        self._sliders.forEach(slider => {
            slider.destroy();
        });
        self._sliders = [];
    },
};



slider.init();
